<template>
  <div class="wrapper">
    <b-col cols="12">
      <b-card>
        <div slot="header">
          {{ $t('wdm16.10997') }}
        </div>
        <b-card no-body>
          <b-tabs card pills vertical nav-wrapper-class="w-40">
            <template v-for="agreement in d_wisdomEraSystem.agreement">
              <b-tab>
                <template slot="title">
                  <i class="icon-pencil"></i> {{ agreement.label }}
                </template>
                <div v-html="d_agreements[agreement.value].version[f_calculateLastVersion(agreement.value)]">
                  {{ d_agreements[agreement.value].version[f_calculateLastVersion(agreement.value)] }}
                </div>
              </b-tab>
            </template>
          </b-tabs>
        </b-card>
      </b-card>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Agreement',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {},
  props: {},
  data () {
    return {
      d_agreements: require('@/options/agreements').agreements,
      d_wisdomEraSystem: {}
    };
  },
  created: function () {
    this.d_wisdomEraSystem = JSON.parse(localStorage.getItem('wisdomera_system'));
  },
  mounted: function () {},
  methods: {
    f_calculateLastVersion: function (agreement_value) {
      return this.d_wisdomEraSystem.agreement[agreement_value].list[this.d_wisdomEraSystem.agreement[agreement_value].list.length - 1].version;
    }
  },
  watch: {}
}

</script>

<style>


</style>

